import React, { useState, useEffect } from "react";
import Cerca from "../../components/cerca/cerca";
import { Link } from 'react-router-dom';

const Sidebar = () => {
    const [showModal, setShowModal] = useState(false);
    const [showOffcanvas, setShowOffcanvas] = useState(false);
    const [apiData, setApiData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch('https://pg.datareputation.com/wp-json/menulist/sidebar');
                if (!response.ok) {
                    throw new Error('Errore nella risposta dell\'API');
                }
                const data = await response.json();
                setApiData(data); // Imposta i dati dell'API nello stato
            } catch (error) {
                console.error('Errore nel recupero dei dati dall\'API:', error);
            }
        };

        fetchData();
    }, []);
    const handleToggleModal = () => {
        setShowModal(!showModal);
    };

    const handleToggleOffcanvas = () => {
        setShowOffcanvas(!showOffcanvas);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };
    const getMunuItems = (parentID) => {
        const filteredItems = apiData.filter(item => item.menu_item_parent == parentID);
        // console.log('Sottomenu sidebar', parentID, ':', filteredItems);
        return filteredItems;
    }
    return (
        <div style={{ borderBottom: '1px solid #ffffff', borderTop: '2px solid #ffffff' }} className="container-fluid">
            <div className="container px-5 mx-5 ">
                <div className="row px-5">
                    <div className="container d-flex">
                        <button style={{borderRadius:'0',borderRight:'1px solid black'}} className="btn" onClick={handleToggleOffcanvas} data-bs-toggle="offcanvas" href="#offcanvasExample" role="button" aria-controls="offcanvasExample">
                            <h6 style={{ fontWeight: 'bold', margin: 0 }}>&#x2630; Naviga</h6>
                        </button>
                        <button className="btn " onClick={handleToggleModal}>
                            <h6 style={{ fontWeight: 'bold', margin: 0 }}>&#128269; Cerca</h6>
                        </button>
                    </div>
                </div>
            </div>

            <div className="offcanvas offcanvas-start" style={{ width: '220px', backgroundColor: '#262327', display: showOffcanvas ? 'block' : 'none' }} id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">
                <div style={{ borderBottom: '1px solid #ffffff77' }} className="offcanvas-header">
                    <h6 style={{ color: '#ffffff' }} className="offcanvas-title" id="offcanvasExampleLabel">Privaci Garantita</h6>
                    <button type="button" className="btn-close btn-close-white" onClick={handleToggleOffcanvas} aria-label="Close"></button>
                </div>

                <div className="offcanvas-body">
                    <ul className="navbar-nav">
                        {getMunuItems(0).map(item => (
                            <li key={item.id} className={getMunuItems(item.ID).length > 0 ? "nav-item dropdown" : "nav-item"}>
                                {getMunuItems(item.ID).length > 0 ? (
                                    <>
                                        <Link to={`/mediadiario/categoria/${item.title}`} className="nav-link sidebar_nav_link text-white dropdown-toggle"  role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                            {item.title}
                                        </Link>
                                        <ul className="dropdown-menu px-2">
                                            {getMunuItems(item.ID).map(subItem => (
                                                <li key={subItem.ID} className="nav-item">
                                                    <Link to={`/categoria/${subItem.title}`} className="nav-link sidebar_nav_link text-black" >
                                                        {subItem.title}
                                                    </Link>
                                                </li>
                                            ))}
                                        </ul>
                                    </>
                                ) : (
                                    <Link to={`/mediadiario/categoria/${item.title}`} className="nav-link sidebar_nav_link text-white" >
                                        {item.title}
                                    </Link>
                                )}
                            </li>
                        ))}
                    </ul>
                </div>

            </div>

            <div className="modal" id="modalSearch" tabIndex="-1" style={{ display: showModal ? 'block' : 'none' }} aria-labelledby="modalSearchLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="modalSearchLabel">Cosa stai cercando?</h5>
                            <button type="button" className="btn-close" onClick={handleToggleModal}></button>
                        </div>
                        {/* Passa la funzione handleCloseModal come prop a Cerca */}
                        <Cerca handleCloseModal={handleCloseModal} />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Sidebar;
