import React, { useState, useEffect } from 'react';
import './navmediadiario.css';
import { Link } from 'react-router-dom';
import Logo from '../logo/logo';

const NavBar2 = () => {
  const [menuItems, setMenuItems] = useState([]);
  const [isSticky, setIsSticky] = useState(false);

  useEffect(() => {
    const fetchMenuItems = async () => {
      try {
        const response = await fetch('https://pg.datareputation.com/wp-json/menulist/principale');
        if (!response.ok) {
          throw new Error('Errore nella risposta dell\'API');
        }
        const data = await response.json();
        // console.log('MenuItems:', data);
        setMenuItems(data);
      } catch (error) {
        console.error('Errore nel recupero dei menu:', error);
      }
    };

    fetchMenuItems();
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const navbar = document.getElementById('stiky');
      const sticky = navbar.offsetTop;

      if (window.pageYOffset >= sticky) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    const handleResize = () => {
      if (window.innerWidth >= 768) {
        handleScroll();
        window.addEventListener('scroll', handleScroll);
      } else {
        window.removeEventListener('scroll', handleScroll);
        setIsSticky(false);
      }
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const getMenuItems = (parentID) => {
    const filteredItems = menuItems.filter(item => item.menu_item_parent == parentID);
    // console.log('Sottomenu estratti per il cazzo di menu padre con ID', parentID, ':', filteredItems);
    return filteredItems;
  };

  return (
    <nav style={{ backgroundColor: '#f1e4d5' }} className={`navbar navbar-expand-lg  nav_custom ${isSticky ? 'stickybar' : ''}`}>
      <div className="container-fluid nav_custom_row mt-lg-1 mb-lg-1 nav_custom_first pb-2 flex-nowrap">
        <div className={`navbar-header ${isSticky ? 'non-sticky-logo' : ''}`}>
          <Logo />
        </div>
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
      </div>
      <div id='stiky' className="container-fluid nav_custom_row border_nav">
        <div className='container'>
          <div className="collapse navbar-collapse" id="navbarNavDropdown">
            <ul style={{ fontWeight: 'bold' }} className="navbar-nav pb-1">
              {/* <li className="nav-item">
                <Link to="/" className="nav-link">
                  Panoramica
                </Link>
              </li> */}
              {menuItems.filter(item => item.menu_item_parent === "0").map(item => (
                <li key={item.ID} className={getMenuItems(item.ID).length > 0 ? "dropdown" : ""}>
                  {getMenuItems(item.ID).length > 0 ? (
                    <>
                      <Link to={`/mediadiario/categoria/${item.title}`} className="nav-link dropdown-toggle" data-bs-toggle="dropdown" role="button" aria-expanded="false">
                        {item.title}
                      </Link>
                      <ul style={{ backgroundColor: '#f1e4d5' }} className="dropdown-menu">
                        {getMenuItems(item.ID).map(subItem => (
                          <li key={subItem.ID} className="">
                            <Link style={{ fontWeight: 'bold', fontSize: '14px' }} to={`/mediadiario/categoria/${subItem.title}`} className="dropdown-item">
                              {subItem.title}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </>
                  ) : (
                    <Link to={`/mediadiario/categoria/${item.title}`} className="nav-link">
                      {item.title}
                    </Link>
                  )}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default NavBar2;
