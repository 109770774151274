import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import './home.css'

const Home = () => {
    const [articolo, setArticolo] = useState([]);

    useEffect(() => {
        fetch('https://pg.datareputation.com/wp-json/wp/v2/posts?_embed')
            .then(response => response.json())
            .then(data => {
                setArticolo(data.slice(0, 8).sort((a, b) => new Date(b.date) - new Date(a.date)));
            })
            .catch(error => {
                console.error('Errore durante la chiamata all\'API:', error);
            });
    }, []);
    return (
        <section style={{ backgroundColor: 'white' }} className="container-fluid p-0 m-0">
            <div className="container-fluid align-middle" style={{ backgroundColor: '#4f8399', marginTop: '145px' }} >
                <div className="container">
                    <div className="d-flex d-inline py-3 justify-content-end align-items-center">
                        <p className="me-3 mb-0 text-light">Resta collegato alle nostre news su questioni di diritto</p>
                        <Link className="btn btn-dark" role="button" to="/mediadiario">News</Link>
                    </div>
                </div>
            </div>

            <div className="container-fluid p-0 m-0">
                <img src="/img/header_privacy_garantita.jpg" className="img-fluid" alt="header_privacy_garantita" />

            </div>

            <div className="container-fluid py-5 px-0" style={{ backgroundColor: '#0077aa' }}>
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-md-10">
                            <h5 className="text-light">
                                Siamo un&apos;agenzia di Reputation Manager che opera prevelentamente sul mercato italiano.
                                {'\n'}Sosteniamo concretamente una transizione energetica socialmente equa creando valore nel lungo termine.
                            </h5>
                        </div>
                        <div className="col-12 col-md-2">
                            <a href="#" className="btn btn-outline-light btn-lg rounded-pill mt-2 mt-md-0 d-block" role="button">CONTATTI</a>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-fluid my-5 pb-4 px-0">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-md-4">
                            <div className="card mt-4">
                                <img src="/img/500x300.png" className="card-img-top" alt="Img card" />
                                <div className="card-body">
                                    <h3 className="card-title">Card title</h3>
                                    <p className="card-text">Some quick example text to build on the card title and make up the bulk of the card content.</p>
                                    <a href="#" className="btn btn-primary">Go somewhere</a>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-md-4">
                            <div className="card mt-4">
                                <img src="/img/500x300.png" className="card-img-top" alt="Img card" />
                                <div className="card-body">
                                    <h3 className="card-title">Card title</h3>
                                    <p className="card-text">Some quick example text to build on the card title and make up the bulk of the card content.</p>
                                    <a href="#" className="btn btn-primary">Go somewhere</a>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-md-4">
                            <div className="card mt-4">
                                <img src="/img/500x300.png" className="card-img-top" alt="Img card" />
                                <div className="card-body">
                                    <h3 className="card-title">Card title</h3>
                                    <p className="card-text">Some quick example text to build on the card title and make up the bulk of the card content.</p>
                                    <a href="#" className="btn btn-primary">Go somewhere</a>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <div className="container-fluid py-5 px-0" style={{ backgroundColor: '#0077aa' }}>
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-md-3">
                            <div className="card text-center mt-4">
                                <div className="card-header bg-light p-0 m-0">
                                    <img src="/img/Senza-titolo-1.jpg" width="100%" />
                                </div>
                                <div className="card-body text-center">
                                    <h3 className="card-title">Special title treatment</h3>
                                    <p className="card-text">With supporting text below as a natural lead-in to additional content.</p>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-md-3">
                            <div className="card text-center mt-4">
                                <div className="card-header bg-light p-0 m-0">
                                    <img src="/img/Senza-titolo-1.jpg" width="100%" />
                                </div>
                                <div className="card-body text-center">
                                    <h3 className="card-title">Special title treatment</h3>
                                    <p className="card-text">With supporting text below as a natural lead-in to additional content.</p>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-md-3">
                            <div className="card text-center mt-4">
                                <div className="card-header bg-light p-0 m-0">
                                    <img src="/img/Senza-titolo-1.jpg" width="100%" />
                                </div>
                                <div className="card-body text-center">
                                    <h3 className="card-title">Special title treatment</h3>
                                    <p className="card-text">With supporting text below as a natural lead-in to additional content.</p>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-md-3">
                            <div className="card text-center mt-4">
                                <div className="card-header bg-light p-0 m-0">
                                    <img src="/img/Senza-titolo-1.jpg" width="100%" />
                                </div>
                                <div className="card-body text-center">
                                    <h3 className="card-title">Special title treatment</h3>
                                    <p className="card-text">With supporting text below as a natural lead-in to additional content.</p>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-md-3">
                            <div className="card text-center mt-4">
                                <div className="card-header bg-light p-0 m-0">
                                    <img src="/img/Senza-titolo-1.jpg" width="100%" />
                                </div>
                                <div className="card-body text-center">
                                    <h3 className="card-title">Special title treatment</h3>
                                    <p className="card-text">With supporting text below as a natural lead-in to additional content.</p>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-md-3">
                            <div className="card text-center mt-4">
                                <div className="card-header bg-light p-0 m-0">
                                    <img src="/img/Senza-titolo-1.jpg" width="100%" />
                                </div>
                                <div className="card-body text-center">
                                    <h3 className="card-title">Special title treatment</h3>
                                    <p className="card-text">With supporting text below as a natural lead-in to additional content.</p>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-md-3">
                            <div className="card text-center mt-4">
                                <div className="card-header bg-light p-0 m-0">
                                    <img src="/img/Senza-titolo-1.jpg" width="100%" />
                                </div>
                                <div className="card-body text-center">
                                    <h3 className="card-title">Special title treatment</h3>
                                    <p className="card-text">With supporting text below as a natural lead-in to additional content.</p>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-md-3">
                            <div className="card text-center mt-4">
                                <div className="card-header bg-light p-0 m-0">
                                    <img src="/img/Senza-titolo-1.jpg" width="100%" />
                                </div>
                                <div className="card-body text-center">
                                    <h3 className="card-title">Special title treatment</h3>
                                    <p className="card-text">With supporting text below as a natural lead-in to additional content.</p>
                                </div>
                            </div>
                        </div>


                    </div>

                </div>
            </div>


            <div className="container-fluid pb-4 px-0 d-none d-md-block" style={{ background: "#6dcda9", height: "450px" }} >
                <div className="container" style={{ paddingTop: "100px" }}>
                    <div className="row ">
                        <div className="col-12 col-md-4">
                            <div className="card mt-4" style={{ background: "#17313b", height: "400px" }}>

                                <div className="card-body">
                                    <p style={{ color: "#6dceab" }}>Find</p>
                                    <h3 className="card-title text-light">Card title</h3>
                                    <p className="card-text text-light">Some quick example text to build on the card title and make up the bulk of the card content.</p>

                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-4">
                            <div className="card mt-4" style={{ background: "#17313b", height: "400px" }}>
                                <div className="card-body">
                                    <p style={{ color: "#6dceab" }}>Find</p>
                                    <h3 className="card-title text-light">Card title</h3>
                                    <p className="card-text text-light">Some quick example text to build on the card title and make up the bulk of the card content.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-4">
                            <div className="card mt-4" style={{ background: "#17313b", height: "400px" }}>

                                <div className="card-body">
                                    <p style={{ color: "#6dceab" }}>Find</p>
                                    <h3 className="card-title text-light">Card title</h3>
                                    <p className="card-text text-light">Some quick example text to build on the card title and make up the bulk of the card content.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="d-md-none container-fluid pb-4 px-0" style={{ background: "#6dcda9" }} >
                <div className="container py-5">
                    <div className="row ">
                        <div className="col-12 col-md-4">
                            <div className="card mt-4" style={{ background: "#17313b", height: "400px" }}>

                                <div className="card-body">
                                    <p style={{ color: "#6dceab" }}>Find</p>
                                    <h3 className="card-title text-light">Card title</h3>
                                    <p className="card-text text-light">Some quick example text to build on the card title and make up the bulk of the card content.</p>

                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-4">
                            <div className="card mt-4" style={{ background: "#17313b", height: "400px" }}>

                                <div className="card-body">
                                    <p style={{ color: "#6dceab" }}>Find</p>
                                    <h3 className="card-title text-light">Card title</h3>
                                    <p className="card-text text-light">Some quick example text to build on the card title and make up the bulk of the card content.</p>

                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-4">
                            <div className="card mt-4" style={{ background: "#17313b", height: "400px" }}>

                                <div className="card-body">
                                    <p style={{ color: "#6dceab" }}>Find</p>
                                    <h3 className="card-title text-light">Card title</h3>
                                    <p className="card-text text-light">Some quick example text to build on the card title and make up the bulk of the card content.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid pb-5 px-0 scopri">
                <div className="container text-center">
                    <h2 className="fw-bold">Join 1.4+ Million leaders who use Clutch each month to connect with a trusted business partner</h2>
                    <a href="#" className="btn btn-outline-dark btn-lg rounded-pill mt-4 text-uppercase" role="button">Scopri di più</a>
                </div>
            </div>
            <div className="container-fluid py-5 px-0" style={{ backgroundColor: "#f2f2f2" }}>
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-md-6">
                            <iframe width="100%" height="315" src="https://www.youtube.com/embed/vN7T2_ERG9Y?si=EVXmk69Dj5cVKmoY&amp;controls=0" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                        </div>
                        <div className="col-12 col-md-6">
                            <h2>Lorem ipsum dolor sit amet</h2>
                            <p> Suspendisse posuere est vel sollicitudin convallis. Mauris eu mattis magna. Etiam vel urna a tellus congue eleifend. Nam posuere odio ac arcu dignissim mollis. Aenean sed nisi nec nibh volutpat mollis. Nulla facilisi. Donec tristique fermentum convallis. Fusce id facilisis eros, sodales euismod sapien</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid py-5 px-0" style={{ backgroundColor: "#000000" }}>
                <div className="container">
                    <div className="row">
                        {articolo.map((article, index) => (
                            <div key={index} className="col-6 mb-3">
                                <div style={{ backgroundColor: "transparent" }} className="card mb-3">
                                    <div className="row no-gutters ">
                                        <div className="col-md-4">
                                        <Link to={`/mediadiario/articolo/${article.slug}`}>
                                            <img src={article._embedded['wp:featuredmedia']['0'].source_url} className="card-img img-fluid img-div-nero" alt="..." />
                                        </Link>
                                        </div>
                                        <div className="col-md-8">
                                            <div className="card-body p-0">
                                            <Link to={`/mediadiario/articolo/${article.slug}`}>
                                                <h5 className="card-title text-white"dangerouslySetInnerHTML={{ __html: article.title.rendered }}></h5>
                                            </Link>
                                                <p style={{ fontSize: "14px" }} className="card-text text-white" > {article._embedded['wp:term']['0'][0].name}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            <div className="container-fluid py-5 px-0" style={{ backgroundColor: '#000000' }}>
                <div className="container ">
                    <footer className="py-5">
                        <div className="row">
                            <div className="col-6 col-md-3 mb-3 text-light">
                                <h5 className="text-uppercase">Section</h5>
                                <ul className="nav flex-column">
                                    <li className="nav-item mb-2"><a href="#" className="nav-link p-0 text-light">Home</a></li>
                                    <li className="nav-item mb-2"><a href="#" className="nav-link p-0 text-light">Features</a></li>
                                    <li className="nav-item mb-2"><a href="#" className="nav-link p-0 text-light">Pricing</a></li>
                                    <li className="nav-item mb-2"><a href="#" className="nav-link p-0 text-light">FAQs</a></li>
                                    <li className="nav-item mb-2"><a href="#" className="nav-link p-0 text-light">About</a></li>
                                </ul>
                            </div>

                            <div className="col-6 col-md-3 mb-3 text-light">
                                <h5 className="text-uppercase">Events</h5>
                                <ul className="nav flex-column">
                                    <li className="nav-item mb-2"><a href="#" className="nav-link p-0 text-light">Home</a></li>
                                    <li className="nav-item mb-2"><a href="#" className="nav-link p-0 text-light">Features</a></li>
                                </ul>
                                <h5 className="text-uppercase mt-3">Media</h5>
                                <ul className="nav flex-column">
                                    <li className="nav-item mb-2"><a href="#" className="nav-link p-0 text-light">Home</a></li>
                                    <li className="nav-item mb-2"><a href="#" className="nav-link p-0 text-light">Features</a></li>
                                    <li className="nav-item mb-2"><a href="#" className="nav-link p-0 text-light">Features</a></li>
                                </ul>
                            </div>

                            <div className="col-6 col-md-3 mb-3 text-light">
                                <h5 className="text-uppercase">Section</h5>
                                <ul className="nav flex-column">
                                    <li className="nav-item mb-2"><a href="#" className="nav-link p-0 text-light">Home</a></li>
                                    <li className="nav-item mb-2"><a href="#" className="nav-link p-0 text-light">Features</a></li>
                                    <li className="nav-item mb-2"><a href="#" className="nav-link p-0 text-light">Pricing</a></li>
                                    <li className="nav-item mb-2"><a href="#" className="nav-link p-0 text-light">FAQs</a></li>
                                    <li className="nav-item mb-2"><a href="#" className="nav-link p-0 text-light">About</a></li>
                                </ul>
                            </div>

                            <div className="col-6 col-md-3 mb-3 text-light">
                                <h5 className="text-uppercase">Partners & Members</h5>
                                <ul className="nav flex-column">
                                    <li className="nav-item mb-2"><a href="#" className="nav-link p-0 text-light">Home</a></li>
                                    <li className="nav-item mb-2"><a href="#" className="nav-link p-0 text-light">Features</a></li>
                                </ul>
                                <h5 className="text-uppercase mt-3">Language edition</h5>
                                <ul className="nav flex-column">
                                    <li className="nav-item mb-2"><a href="#" className="nav-link p-0 text-light">Home</a></li>
                                    <li className="nav-item mb-2"><a href="#" className="nav-link p-0 text-light">Features</a></li>
                                    <li className="nav-item mb-2"><a href="#" className="nav-link p-0 text-light">Pricing</a></li>
                                    <li className="nav-item mb-2"><a href="#" className="nav-link p-0 text-light">FAQs</a></li>
                                </ul>
                            </div>
                        </div>

                        <div className="d-flex flex-column flex-sm-row justify-content-between py-4 my-4 border-top text-light" style={{ borderTopColor: '#ffffff' }}>
                            <p>© 2024 Company, Inc. All rights reserved.</p>
                            {/* <ul className="list-unstyled d-flex">
                                
                            </ul> */}
                        </div>
                    </footer>
                </div>
            </div>
        </section>
    );
};

export default Home;