import React from 'react';

const Skeletor = () => {
    return (
        <div>
            <div className='row'>
                {[...Array(5).keys()].map((index) => (
                    <div key={index} className="col-12">
                        <div style={{ backgroundColor: 'transparent' }} className="card border-0 p-md-2">
                            <div style={{ width: '100%' }} src="" className=" bg-loading card-img-top card custom_card_premium object-fit-cover" alt="..." />
                            <div className="card-body">
                                <>
                                    <h6 className="card-subtitle mb-2 blu"></h6>
                                    <p className="card-text"></p>
                                    <p style={{ fontSize: '14px' }} className='text-secondary d-flex align-self-start mt-2'> </p>
                                </>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default Skeletor;
