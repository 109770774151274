import React, { useEffect, useState } from 'react';
import { useLocation, Link } from 'react-router-dom';
import Ultimenews from '../../components/ultimenews/ultimenews';

const RisultatiRicerca = () => {
    const location = useLocation();
    const [searchTerm, setSearchTerm] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [loading, setLoading] = useState(false);

    const fetchSearchResults = (term) => {
        setLoading(true);
        fetch(`https://pg.datareputation.com/wp-json/wp/v2/posts?search=${term}&_embed`)
            .then(response => response.json())
            .then(data => {
                setSearchResults(data);
                setLoading(false);
            })
            .catch(error => {
                console.error("Errore durante la ricerca:", error);
                setLoading(false);
            });
    };

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const term = params.get('term');
        setSearchTerm(term);

        if (term) {
            fetchSearchResults(term);
        }
    }, [location.search]);

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const handleSearchSubmit = (event) => {
        event.preventDefault();
        fetchSearchResults(searchTerm);
    };

    return (
        <div className='p-4 p-md-5'>
            <div style={{ maxWidth: '1020px', margin: '0 auto' }} className='container'>
                <div className='row'>
                    <div className='col-12 col-md-8  border-right'>
                        <div className='row'>
                            <div className="mb-2">Risultati della ricerca per:</div>
                            <form style={{ borderBottom: '1px solid #ccc' }} className="form-label d-flex mb-2 pb-2" onSubmit={handleSearchSubmit}>
                                <input
                                    style={{ width: '50%' }}
                                    className="form-control me-4"
                                    type="text"
                                    value={searchTerm}
                                    onChange={handleSearchChange}
                                    placeholder="Inserisci il termine di ricerca"
                                />
                                <button className='btn btn-secondary' type="submit">Cerca</button>
                            </form>
                        </div>
                        {loading && <p>Caricamento...</p>}
                        {!loading && searchResults.length === 0 && <p>Nessun risultato trovato.</p>}
                        {!loading && searchResults.length > 0 && (
                            <div>
                                {searchResults.map(result => (
                                    <div style={{ borderBottom: '1px solid #ccc' }} className="row py-4" key={result.id}>
                                        <div className="col-md-4">
                                            {result._embedded && result._embedded['wp:featuredmedia'] && result._embedded['wp:featuredmedia'][0] && (
                                                <img style={{ borderRadius: '0px', marginRight: '10px' }} src={result._embedded['wp:featuredmedia'][0].source_url} width={200} height={100} alt={result.title.rendered} />
                                            )}
                                            {!result._embedded || !result._embedded['wp:featuredmedia'] && (
                                                <p>Immagine non disponibile</p>
                                            )}
                                        </div>
                                        <div className="col-md-8">
                                            <Link to={`/mediadiario/articolo/${result.slug}`}>
                                                <div dangerouslySetInnerHTML={{ __html: result.title.rendered }} />
                                            </Link>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )}



                </div>

                <div className='col-12 col-md-4 ps-2'>
                    <h5 className='px-2 pt-5 pt-md-0' style={{ fontSize: '16px', fontWeight: 'bold' }}>Le ultime news</h5>
                    <div className='border-top-article pb-3 pb-md-0'></div>
                    <Ultimenews />
                </div>
            </div>
        </div>
        </div >
    );
};

export default RisultatiRicerca;
