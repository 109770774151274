import React, { useState, useEffect } from 'react';
import './newscontainercategory.css';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Ultimenews from '../../components/ultimenews/ultimenews';
import Skeletor from './skeletor';

const ContainerCategory = ({ categoryId }) => {
  ContainerCategory.propTypes = {
    categoryId: PropTypes.string.isRequired,
  };

  const [articoloCategory, setArticoloCategory] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetch(`https://pg.datareputation.com/wp-json/wp/v2/posts?categories=${categoryId}&_embed`)
      .then(response => response.json())
      .then(data => {
        setArticoloCategory(data.slice(0, 5).sort((a, b) => new Date(b.date) - new Date(a.date)));
        setLoading(false);
      })
      .catch(error => {
        console.error('Errore durante la chiamata all\'API:', error);
        setLoading(false);
      });
  }, [categoryId]);

  if (loading) {
    return <Skeletor />;
  }

  if (articoloCategory.length === 0) {
    return <p className='text-center mt-1'>Non ci sono articoli per questa categoria.</p>;
  }

  return (
    <div className='p-4 p-md-5'>
      <div style={{ maxWidth: '1020px', margin: '0 auto' }} className='container'>
        <div className='row'>
          <div className='col-12 col-md-8 pe-3 border-right'>
            <div>
              <div className='div_category order-2 order-lg-1'>
                <p className='blu'>{articoloCategory[0]?._embedded['wp:term']?.[0]?.[0]?.name}</p>
                <Link to={`/mediadiario/articolo/${articoloCategory[0]?.slug}`}>
                  <h3 className=' font-weight-bold ' dangerouslySetInnerHTML={{ __html: articoloCategory[0]?.title?.rendered }}></h3>
                </Link>
                <p style={{ fontSize: '14px' }} className='text-secondary'> di {articoloCategory[0]?.['_embedded']?.['author']?.[0]?.name}</p>
              </div>
              <Link to={`/mediadiario/articolo/${articoloCategory[0]?.slug}`}>
                <img src={articoloCategory[0]?.['_embedded']?.['wp:featuredmedia']?.['0']?.source_url} width={200} height={200} className="card-img-top  object-fit-cover border-radius rounded category_img order-1 order-lg-2 mb-4 " alt="..." />
              </Link>
              <div className='row '>
                <div className=' d-flex flex-column  '>
                  <div className='row py-4 border_top'>
                    {articoloCategory.slice(1).map(article => (
                      <div key={article?.slug} className='col-12 col-md-6 mt-5'>
                        <p className='blu d-flex align-self-start '>{article?.['_embedded']?.['wp:term']?.[0]?.[0]?.name}</p>
                        <Link to={`/mediadiario/articolo/${article?.slug}`}>
                          <p className='card-text' dangerouslySetInnerHTML={{ __html: article?.title?.rendered }}></p>
                        </Link>
                        <p style={{ fontSize: '14px' }} className='text-secondary d-flex align-self-start mt-2'> di {article?.['_embedded']?.['author']?.[0]?.name}</p>
                        <Link to={`/mediadiario/articolo/${article?.slug}`}>
                          <img src={article?.['_embedded']?.['wp:featuredmedia']?.['0']?.source_url} width={200} height={200} className="card-img-top  object-fit-cover border-radius rounded  " alt="..." />
                        </Link>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='col-12 col-md-4'>
            <h5 className='px-2 pt-5 pt-md-0' style={{ fontSize: '14px', fontWeight: 'bold' }}>Le ultime news</h5>
            <div className='border-top-article pb-3 pb-md-0'></div>
            <Ultimenews />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContainerCategory;
