import React, { useState, useEffect } from 'react';
import './newscontainerprefooter.css';
import { Link } from 'react-router-dom';
import Skeletor from "../../components/newscontainer_pre_footer/skeletor";
const NewsContainerPremium = () => {
    const [articolo, setArticolo] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        fetch('https://pg.datareputation.com/wp-json/wp/v2/posts?_embed')
            .then(response => response.json())
            .then(data => {
                const articoliSenzaVideo = data.filter(post => post.format !== 'video');
                setArticolo(articoliSenzaVideo.slice(0, 6).sort((a, b) => new Date(b.date) - new Date(a.date)));
                setLoading(false);
            })
            .catch(error => {
                console.error('Errore durante la chiamata all\'API:', error);
                setLoading(false);
            });
    }, []);

    if (loading) {
        return <Skeletor />;
    }

    return (
        <div style={{  width: '80%' }} className="container">
            <div className="mx-0 mx-md-2">
                <div style={{ borderTop: '1px solid #ccc' }} className="row">
                    <div className="col-12 col-md-6">
                        <div style={{ paddingBottom:'0px'}} className="card border-0 pt-md-3 bg-transparent">
                            <Link to={`/mediadiario/articolo/${articolo[0].slug}`}>
                                <img style={{ borderRadius: '0px' }} src={articolo[0]._embedded['wp:featuredmedia']['0'].source_url} width={200} height={200} className="card-img-top card custom_card_premium_big object-fit-cover" alt="..." />
                            </Link>
                            <div className="card-body p-3 p-md-1 py-md-2 ">
                                <h6 className="card-subtitle mb-2 blu text-start-md-text-center">{articolo[0]._embedded['wp:term']['0'][0].name}</h6>
                                <Link to={`/mediadiario/articolo/${articolo[0].slug}`}>
                                    <p className="card-text titolo_big3" dangerouslySetInnerHTML={{ __html: articolo[0].title.rendered }}></p>
                                </Link>
                                <p style={{ fontSize: '14px' }} className='text-secondary text-start-md-text-center mt-2'> di {articolo[0]._embedded['author']['0'].name}</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-6">
                        <div className="row">
                            {articolo.slice(1, 5).map((article, index) => (
                                <div key={index} className="col-6">
                                    <div className="card border-0 pt-md-3 bg-transparent">
                                        <Link to={`/mediadiario/articolo/${article.slug}`}>
                                            {index < 2 && (
                                                <img style={{ borderRadius: '0px' }} src={article._embedded['wp:featuredmedia']['0'].source_url} width={200} height={200} className="card-img-top card custom_card_premium object-fit-cover" alt="..." />
                                            )}
                                        </Link>
                                        <div className="card-body p-3 p-md-1 py-md-2">
                                            <h6 className="card-subtitle mb-2 blu">{article._embedded['wp:term']['0'][0].name}</h6>
                                            <Link to={`/mediadiario/articolo/${article.slug}`}>
                                                <p className="card-text titolo_news" dangerouslySetInnerHTML={{ __html: article.title.rendered }}></p>
                                            </Link>
                                            <p style={{ fontSize: '14px' }} className='text-secondary d-flex align-self-start mt-2'> di {article._embedded['author']['0'].name}</p>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NewsContainerPremium;

