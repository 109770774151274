import React, { useState } from "react";
import PropTypes from 'prop-types'; 
import { useNavigate } from "react-router-dom";
import './cerca.css';

const Cerca = ({ handleCloseModal }) => {
    const [searchTerm, setSearchTerm] = useState("");
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate(); 

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const handleSearchSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);

        try {
            // Effettua la richiesta API per ottenere i risultati della ricerca
            const response = await fetch(`https://pg.datareputation.com/wp-json/wp/v2/posts?search=${searchTerm}`);
            const data = await response.json();

            // Naviga alla pagina dei risultati della ricerca con il termine di ricerca come parametro nell'URL
            navigate(`/mediadiario/risultati-ricerca?term=${searchTerm}`);
        } catch (error) {
            console.error("Errore durante la ricerca:", error);
        }
        setLoading(false);
        handleCloseModal(); // Chiudi il popup dopo l'invio della ricerca
    };

    return (
        <div className="modal-content">
            <div className="modal-body">
                <form onSubmit={handleSearchSubmit}>
                    <input
                        type="text"
                        value={searchTerm}
                        onChange={handleSearchChange}
                        placeholder="Cerca..."
                    />
                    <button type="submit" className="btn btn-secondary btn-sm my-2 mx-2" disabled={loading}>
                        Cerca
                    </button>
                </form>
                {loading && <p>Caricamento...</p>}
            </div>
        </div>
    );
};

Cerca.propTypes = {
    handleCloseModal: PropTypes.func.isRequired // Proprietà che specifica il tipo di dato che deve essere fornito senno non worka!
};

export default Cerca;
