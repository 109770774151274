import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
// import NewsContainer from "../../components/newcontainer/newscontainer";
import NewsContainerCategory from "../../components/newscontainer_category/newscontainercategory";
import NewsContainerPremium from "../../components/newscontainer_premium/newscontainerpremium";
import Redazione from "../../components/redazione/redazione";
const CategoryPage = () => {
  const { categorySlug } = useParams();
  const [categoryId, setCategoryId] = useState(null);

  useEffect(() => {
    fetch(`https://pg.datareputation.com/wp-json/wp/v2/categories?slug=${categorySlug}`)
      .then(response => response.json())
      .then(data => {
        if (data.length > 0) {
          setCategoryId(data[0].id);
        }
      })
      .catch(error => console.error('Errore durante la chiamata all\'API:', error));
  }, [categorySlug]);
  return (
    <div>
      {/* <h6 className='text-center'> Categoria: {categorySlug}</h6> */}
      {categoryId && (
        <NewsContainerCategory categoryId={categoryId} />
      )}
      <NewsContainerPremium />
      {/* <Redazione /> */}
    </div>
  );
}

export default CategoryPage;