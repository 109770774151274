import React from "react";
import './footer.css';

const Footer = () => {
    return (
        <footer className="footer container-fluid p-0 mt-4">
            <img style={{height: '100%'}} src="/img/logo-news-white.png"   alt="Footer Logo" />
        </footer>
    );
} 

export default Footer;