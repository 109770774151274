import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Skeletor from "../../components/ultimenews/skeletor";

const Ultimenews = () => {
  const [articolo, setArticolo] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetch('https://pg.datareputation.com/wp-json/wp/v2/posts?_embed')
      .then(response => response.json())
      .then(data => {
        setArticolo(data.slice(0, 5).sort((a, b) => new Date(b.date) - new Date(a.date)));
        setLoading(false);
      })
      .catch(error => {
        console.error('Errore durante la chiamata all\'API:', error);
        setLoading(false);
      });
  }, []);

  if (loading) {
    return (
      <Skeletor />
    );
  }

  if (articolo.length === 0) {
    return <p className='text-center py-5'>Non ci sono articoli</p>;
  }

  return (
    <div>
      <div className='row'>
        {articolo.map((article, index) => (
          <div key={index} className="col-12">
            <div style={{ backgroundColor: 'transparent' }} className="card border-0 p-md-2">
              <Link to={`/mediadiario/articolo/${article.slug}`}>
                {loading ? (
                  <div className="loading-container"></div>
                ) : (
                  <img style={{ width: '100%' ,height: '150px' }} src={article._embedded['wp:featuredmedia']['0'].source_url} width={200} height={200} className="card-img-top card custom_card_premium object-fit-cover" alt="..." />
                )}
              </Link>
              <div className="card-body ps-1">
                {loading ? (
                  <>
                    <div className="loading-container"></div>
                    <div className="loading-container"></div>
                  </>
                ) : (
                  <>
                    <h6 style={{ fontSize: '12px' }} className="card-subtitle mb-2 blu">{article._embedded['wp:term']['0'][0].name}</h6>
                    <Link to={`/mediadiario/articolo/${article.slug}`}>
                      <p className="card-text titolo_articolo" dangerouslySetInnerHTML={{ __html: article.title.rendered }}></p>
                    </Link>
                    <p style={{ fontSize: '12px' }} className='text-secondary d-flex align-self-start mt-2'> di {article._embedded['author']['0'].name}</p>
                  </>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Ultimenews;
