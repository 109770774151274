import React from "react";
import NewsContainer from "../../components/newcontainer/newscontainer";
import NewsContainer3 from "../../components/newcontainer3/newscontainer3";
import NewsContainerSub from "../../components/newscontainer_sub/newscontainersub";
import NewsContainerPremium from "../../components/newscontainer_premium/newscontainerpremium";
import NewsContainerVideo from"../../components/newcontainer_video/newscontainervideo";
import NewsContainerPreFooter from "../../components/newscontainer_pre_footer/newscontainerprefooter";
import Redazione from "../../components/redazione/redazione";

const Home2 = () => {
    return (
        <div>
            <NewsContainer3 />
            <NewsContainerSub />
            <NewsContainerPremium />
            <NewsContainerVideo />
            {/* <NewsContainer /> */}
            <NewsContainerPreFooter />
            {/* <Redazione /> */}
        </div>
    );
};

export default Home2;