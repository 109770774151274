import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import './articledetails.css';
import Ultimenews from '../../components/ultimenews/ultimenews';
import Skeletor from './skeletor';

const ArticleDetails = () => {
  const { slug } = useParams();
  const [article, setArticle] = useState({});
  const [loading, setLoading] = useState(true);
  const [videoFound, setVideoFound] = useState(false);
  const [videoLoaded, setVideoLoaded] = useState(false);
  const contentRef = useRef(null);

  useEffect(() => {
    const fetchArticle = async () => {
      try {
        const response = await fetch(`https://pg.datareputation.com/wp-json/wp/v2/posts?slug=${slug}&_embed`);
        if (!response.ok) {
          throw new Error('Errore durante il recupero dell\'articolo');
        }
        const data = await response.json();
        if (Array.isArray(data) && data.length > 0) {
          setArticle(data[0]);
          setLoading(false);
          const hasIframe = data[0].content.rendered.includes('<iframe');
          setVideoFound(hasIframe);
          // Imposta il meta description
          document.querySelector('meta[name="description"]').setAttribute('content', data[0].yoast_head_json?.description || '');
        } else {
          setLoading(false);
        }
      } catch (error) {
        console.error('Errore durante la chiamata all\'API:', error);
        setLoading(false);
      }
    };

    fetchArticle();
  }, [slug]);

  useEffect(() => {
    if (contentRef.current && contentRef.current.querySelector('iframe')) {
      setVideoLoaded(true);
    }
  }, [article]);

  const termName = article._embedded?.['wp:term']?.['0']?.[0]?.name;
  const authorName = article._embedded?.author?.['0']?.name;
  const imageUrl = article?._embedded?.['wp:featuredmedia']?.['0']?.source_url;

  if (loading) {
    return <Skeletor />;
  }

  if (!article || Object.keys(article).length === 0) {
    return <p className='text-center py-5'>Oops! Articolo non trovato.</p>;
  }

  return (
    <>
      <Helmet>
        <title>{article.yoast_head_json?.title}</title>
      </Helmet>
      <div className='p-4 p-md-5'>
        <div style={{ maxWidth: '1020px', margin: '0 auto' }} className='container'>
          <div className='row'>
            <div className='col-12 col-md-8  border-right'>
              <div className='order-2 order-lg-1 mb-3'>
                <p className='blu d-flex align-self-start'>{termName}</p>
                <h3 className='font-weight-bold titolo_big_articolo' dangerouslySetInnerHTML={{ __html: article.title.rendered }}></h3>
                <p style={{ fontSize: '14px' }} className='text-secondary d-flex align-self-start mt-2'> di {authorName}</p>
                {videoFound && !videoLoaded && (
                  <p>Caricamento video...</p>
                )}
                {!videoFound && (
                  <img
                    src={imageUrl}
                    width={200}
                    height={200}
                    className="category_img card-img-top rounded-2 object-fit-cover order-1 order-lg-2 mb-4"
                    alt="..."
                  />
                )}
                <div ref={contentRef} dangerouslySetInnerHTML={{ __html: article.content.rendered }} />
              </div>
            </div>
            <div className='col-12 col-md-4 ps-2'>
              <h5 className='px-2 pt-5 pt-md-0' style={{ fontSize: '16px', fontWeight: 'bold' }}>Le ultime news</h5>
              <div className='border-top-article pb-3 pb-md-0'></div>
              <Ultimenews />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ArticleDetails;
