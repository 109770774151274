import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Nav from './components/nav/nav';
import Nav2 from './components/navMediaDiario/navmediadiario';
import Sidebar from './components/sidebar/sidebar';
import Footer from './components/footer/footer';
import Home from './pages/home/home';
import Home2 from './pages/homeMediaDiario/homemediadiario';
import CategoryPage from './pages/category/category';
import ArticleDetails from './pages/articledetail/articledetail';
import Redazione from './pages/redazione/redazione';
import Cerca from './components/cerca/cerca';
import RisultatiRicerca from './pages/risultatiricerca/risultatiricerca';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<FirstSessionLayout />} />
        <Route path="/mediadiario/*" element={<MediaDiarioLayout />} />
      </Routes>
    </Router>
  );
}

function FirstSessionLayout() {
  return (
    <div>
      <Nav />
      <Routes>
        <Route path="/" element={<Home />} />
      </Routes>
    </div>
  );
}

function MediaDiarioLayout() {
  return (
    <div>
      <Sidebar />
      <Nav2 />
      <Routes>
        <Route path="/" element={<Home2 />} />
        <Route path="/categoria/:categorySlug" element={<CategoryPage />} />
        <Route path="/articolo/:slug" element={<ArticleDetails />} />
        <Route path="/redazione" element={<Redazione />} />
        <Route path="/cerca" element={<Cerca />} />
        <Route path="/risultati-ricerca" element={<RisultatiRicerca />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
