import React, { useState, useEffect } from 'react';
import './newscontainervideo.css';
import { Link } from 'react-router-dom';

const NewsContainerVideo = () => {
    const [videoArticles, setVideoArticles] = useState([]);

    useEffect(() => {
        fetch('https://pg.datareputation.com/wp-json/wp/v2/posts?_embed')
            .then(response => response.json())
            .then(data => {
                // Filtra solo gli articoli che contengono un video
                const articoliConVideo = data.filter(post => post.content.rendered.includes('<iframe'));

                // Ordina gli articoli per data in ordine decrescente
                articoliConVideo.sort((a, b) => new Date(b.date) - new Date(a.date));

                // Prendi solo i primi 4 articoli
                const primiQuattroArticoli = articoliConVideo.slice(0, 4);

                // Imposta lo stato con i primi 4 articoli
                setVideoArticles(primiQuattroArticoli);
            })
            .catch(error => console.error('Errore nella richiesta:', error));
    }, []);

    return (
        <div style={{ backgroundColor: '#007880 ', borderRadius: '5px', width: '80%' }} className="container my-5 pb-3">
            <div className='mx-0 mx-md-5 pt-4'>
                <div style={{ borderBottom: '1px solid white' }} className='row'>
                    <div className='col-12'>
                        <h4 style={{ color: 'white' }}>Video</h4>
                    </div>
                </div>
                <div className='row'>
                    {videoArticles.map(article => (
                        <div key={article.id} className='col-12 col-md-3'>
                            <div className="card custom_card_video my-3">
                                <Link to={`/mediadiario/articolo/${article.slug}`}>
                                    <img style={{ borderRadius: '0px', height: '150px' }} src={article._embedded['wp:featuredmedia']['0'].source_url} width={200} height={200} className="card-img-top card custom_card_premium object-fit-cover" alt="..." />
                                </Link>
                                <div className="card-body p-3 p-md-1 py-md-2">
                                    <h6 style={{ color: '#007880', fontSize: '12px' }} className="card-subtitle mb-2">{article._embedded['wp:term']['0'][0].name}</h6>
                                    <Link to={`/mediadiario/articolo/${article.slug}`}>
                                        <p style={{ color: 'white' }} className="card-text pb-1 pb-md-3 " dangerouslySetInnerHTML={{ __html: article.title.rendered }}></p>
                                    </Link>
                                    <p style={{ fontSize: '12px', color: 'rgba(255, 255, 255, 0.7)' }} className=' d-flex align-self-start'> di {article._embedded['author']['0'].name}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default NewsContainerVideo;
