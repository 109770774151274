import React from "react";
const Skeletor = () => {
    return (
        <div className="container">
            <div className='mx-0 mx-md-5 pt-4'>
                <div className='row mx-0 mx-md-5'>
                    <div className='col-12 col-md-6 order-2 col-lg-3 order-lg-1'>
                        <div style={{ flexDirection: 'column' }} className='row'>
                            <div className='col-12 '>
                                <div className="card custom_card">
                                    <div className="loading-container3"></div>
                                    <div className="card-body">
                                        <h6 className="card-subtitle mb-2 blu"></h6>
                                        <p className="card-text"></p>
                                        <p style={{ fontSize: '14px' }} className='text-secondary d-flex align-self-start mt-2'></p>
                                    </div>
                                </div>
                            </div>
                            {/* <div className='col-12 mt-2'>
                                <div className="card custom_card">
                                    <div className="loading-container"></div>
                                    <div className="card-body">
                                        <h6 className="card-subtitle mb-2 blu"></h6>
                                            <p className="card-text"></p>
                                        <p style={{ fontSize: '14px' }} className='text-secondary d-flex align-self-start mt-2'></p>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                    <div className='col-12 col-md-12 d-flex flex-column align-items-center news_big order-1 col-lg-6 order-lg-2 '>
                        <div style={{ width: '100%' }} className="card custom_card">
                        <div className="loading-container_big3"></div>
                            <div className="card-body">
                                <h6 className="card-subtitle mb-2 blu"></h6>
                                    <p className="card-text"></p>
                                <p style={{ fontSize: '14px' }} className='text-secondary d-flex align-self-start mt-2'></p>
                            </div>
                        </div>
                    </div>
                    <div className='col-12 col-md-6 order-3 col-lg-3 order-lg-3' >
                        <div style={{ flexDirection: 'column' }} className='row'>
                            <div className='col-12'>
                                <div className="card custom_card">
                                <div className="loading-container3"></div>
                                    <div className="card-body">
                                        <h6 className="card-subtitle mb-2 blu"></h6>
                                            <p className="card-text"></p>
                                        <p style={{ fontSize: '14px' }} className='text-secondary d-flex align-self-start mt-2'></p>
                                    </div>
                                </div>
                            </div>
                            {/* <div className='col-12 mt-2'>
                                <div className="card custom_card">
                                <div className="loading-container"></div>
                                    <div className="card-body">
                                        <h6 className="card-subtitle mb-2 blu"></h6>
                                            <p className="card-text"></p>
                                        <p style={{ fontSize: '14px' }} className='text-secondary d-flex align-self-start mt-2'></p>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default Skeletor;