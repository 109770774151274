import React, { useState, useEffect } from 'react';
import './newscontainersub.css';
import { Link } from 'react-router-dom';
import { BsChevronLeft, BsChevronRight } from 'react-icons/bs';
import Skeletor from './skeletor';
const NewsContainerSub = () => {
    const [articoli, setArticoli] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const articlesPerPage = 6;
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        fetch('https://pg.datareputation.com/wp-json/wp/v2/posts?_embed')
            .then(response => response.json())
            .then(data => {
                const orderedArticoli = data.sort((a, b) => new Date(b.date) - new Date(a.date));
                const articoliEsclusi = orderedArticoli.slice(4);
                setArticoli(articoliEsclusi);
                setLoading(false);
            })
            .catch(error => {
                console.error('Errore durante la chiamata all\'API:', error);
                setLoading(false);
            })
    }, []);

    const indexOfLastArticle = currentPage * articlesPerPage;
    const indexOfFirstArticle = indexOfLastArticle - articlesPerPage;
    const currentArticles = articoli.slice(indexOfFirstArticle, indexOfLastArticle);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);
    if (loading) {
        return (
            <Skeletor />
        );
    }
    if (articoli.length === 0) {
        return <p className='text-center mt-1'>Non ci sono altri articoli</p>;
    }
    return (
        <div className='container mb-5'>
            <div className=' mx-0 mx-md-5 pt-4 news_sub_container'>
                <div className="row mx-0 mx-md-5">
                    {/* Prima fila */}
                    {currentArticles.slice(0, 3).map((article, index) => (
                        <div key={index} className="col-12 col-md-4 order-2 col-lg-4 order-lg-1 px-4 px-md-1">
                            <div className="card custom_card">
                                <div className="card-body p-3 p-md-1 py-md-2">
                                    <Link to={`/mediadiario/articolo/${article.slug}`}>
                                        <img style={{ borderRadius: '0px' }} src={article._embedded['wp:featuredmedia']['0'].source_url} width={200} height={200} className="card-img-top card custom_sub_card_img object-fit-cover mb-3" alt="..." />
                                    </Link>
                                    <h6 style={{ fontSize: '12px' ,color:"red"}} className="card-subtitle mb-2 ">{article._embedded['wp:term']['0'][0].name}</h6>
                                    <Link to={`/mediadiario/articolo/${article.slug}`}>
                                        <p className="card-text titolo_news" dangerouslySetInnerHTML={{ __html: article.title.rendered }}></p>
                                    </Link>
                                    <p style={{ fontSize: '12px' }} className='text-secondary d-flex align-self-start mt-2'> di {article._embedded['author']['0'].name}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                <div className="row mx-0 mx-md-5">
                    {/* Seconda fila */}
                    {currentArticles.slice(3, 6).map((article, index) => (
                        <div key={index} className="col-12 col-md-4 order-2 col-lg-4 order-lg-1 px-4 px-md-1">
                            <div className="card custom_card">

                                <div className="card-body p-3 p-md-1 py-md-2">
                                    <h6 style={{ fontSize: '12px' ,color:"red"}} className="card-subtitle mb-2">{article._embedded['wp:term']['0'][0].name}</h6>
                                    <Link to={`/mediadiario/articolo/${article.slug}`}>
                                        <p className="card-text titolo_news" dangerouslySetInnerHTML={{ __html: article.title.rendered }}></p>
                                    </Link>
                                    <p style={{ fontSize: '12px' }} className='text-secondary d-flex align-self-start mt-2'> di {article._embedded['author']['0'].name}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                {/* Paginazione */}
                {/* <div className="d-flex justify-content-center mt-4">
                <nav>
                    <ul className="pagination">
                        <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                            <span className="page-link" onClick={() => paginate(currentPage - 1)} aria-label="Previous">
                                <BsChevronLeft />
                            </span>
                        </li>
                        {Array.from({ length: Math.ceil(articoli.length / articlesPerPage) }, (_, i) => (
                            <li key={i} className={`page-item ${currentPage === i + 1 ? 'active' : ''}`}>
                                <span className="page-link" onClick={() => paginate(i + 1)}>
                                    {i + 1}
                                </span>
                            </li>
                        ))}
                        <li className={`page-item ${currentPage === Math.ceil(articoli.length / articlesPerPage) ? 'disabled' : ''}`}>
                            <span className="page-link" onClick={() => paginate(currentPage + 1)} aria-label="Next">
                                <BsChevronRight />
                            </span>
                        </li>
                    </ul>
                </nav>
            </div> */}
            </div>
        </div>

    );
};

export default NewsContainerSub;
