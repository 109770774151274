import React from "react";

const SkeletorPremium = () => {
    return (
        <div  className="container p-5">
            <div className="row">
                <div className="col-md-6">
                    <div className="loading-container custom_card_premium_big"></div>
                </div>
                <div className="col-md-3">
                    <div style={{ width: '200px', height: '200px' }} className="loading-container custom_card_premium"></div>
                </div>
                <div className="col-md-3">
                    <div style={{ width: '200px', height: '200px' }} className="loading-container custom_card_premium"></div>
                </div>
            </div>
        </div>
    );
}

export default SkeletorPremium;
