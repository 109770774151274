// LogoLink.jsx
import React from 'react';
import { Link } from 'react-router-dom';

const Logo = () => {
  return (
    <Link to="/" className="logo-link">
      <img
        src="/img/logo-news.png"
        className="logo"
        alt="logo"
        width={250}
        height={100}
      />
    </Link>
  );
};

export default Logo;
