import React, { useState, useEffect } from 'react';
import './newscontainer3.css';
import { Link } from 'react-router-dom';
import Skeletor from "../newcontainer3/skeletor";
const NewsContainer = () => {
  const [articolo, setArticolo] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetch('https://pg.datareputation.com/wp-json/wp/v2/posts?_embed')
      .then(response => response.json())
      .then(data => {
        const articoliSenzaVideo = data.filter(post => post.format !== 'video');
        setArticolo(articoliSenzaVideo.slice(0, 6).sort((a, b) => new Date(b.date) - new Date(a.date)));
        setLoading(false); // Imposta lo stato di caricamento su false quando i dati sono pronti
      })
      .catch(error => {
        console.error('Errore durante la chiamata all\'API:', error);
        setLoading(false); // Imposta lo stato di caricamento su false anche in caso di errore
      });
  }, []);

  if (loading) {
    // Caricamento temporaneo
    return (
      <Skeletor />
    );
  }

  if (articolo.length === 0) {
    return <p className='text-center py-5'>Non ci sono articoli in primo piano.</p>;
  }

  return (
    <div className='container'>
      {articolo.length > 0 && articolo[0] && articolo[1] && articolo[2] && articolo[3] && articolo[4] && (
        <div className='mx-0 mx-md-5 pt-4'>
          <div className='row mx-0 mx-md-3 '>
            <div className='col-12 col-md-6 order-2 col-lg-3 order-lg-1'>
              <div style={{ flexDirection: 'column' }} className='row'>
                <div className='col-12 '>
                  <div className="card custom_card">
                    <Link to={`/mediadiario/articolo/${articolo[1].slug}`}>
                      <img src={articolo[1]._embedded['wp:featuredmedia']['0'].source_url} width={200} height={200} className="card-img-top custom_card_img3 " alt="..." />
                    </Link>
                    <div className="card-body p-3 p-md-1 py-md-2">
                      <h6 className="card-subtitle mb-2 blu">{articolo[0]._embedded['wp:term']['0'][0].name}</h6>
                      <Link to={`/mediadiario/articolo/${articolo[1].slug}`}>
                        <p className="card-text titolo_news" dangerouslySetInnerHTML={{ __html: articolo[1].title.rendered }}></p>
                      </Link>
                      <p style={{ fontSize: '12px' }} className='text-secondary d-flex align-self-start mt-2'> di {articolo[1]._embedded['author']['0'].name}</p>
                    </div>
                  </div>
                </div>
                <div className='col-12 mt-2'>
                  <div className="card custom_card">
                    <div className="card-body  p-3 p-md-1 py-md-2">
                      <h6 className="card-subtitle mb-2 blu">{articolo[2]._embedded['wp:term']['0'][0].name}</h6>
                      <Link to={`/mediadiario/articolo/${articolo[2].slug}`}>
                        <p className="card-text titolo_news" dangerouslySetInnerHTML={{ __html: articolo[2].title.rendered }}></p>
                      </Link>
                      <p style={{ fontSize: '12px' }} className='text-secondary d-flex align-self-start mt-2'> di {articolo[2]._embedded['author']['0'].name}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-12 col-md-12 d-flex flex-column align-items-center news_big order-1 col-lg-6 order-lg-2 '>
              <div className="card custom_card">
                <Link to={`/mediadiario/articolo/${articolo[0].slug}`}>
                  <img style={{ borderRadius: '0px' }} src={articolo[0]._embedded['wp:featuredmedia']['0'].source_url} width={450} height={450} className="card-img-top custom_card_img_big object-fit-cover" alt="..." />
                </Link>
                <div className="card-body p-3 p-md-1 py-md-2">
                  <h6 className="card-subtitle mb-2 blu text-start-md-text-center">{articolo[0]._embedded['wp:term']['0'][0].name}</h6>
                  <Link to={`/mediadiario/articolo/${articolo[0].slug}`}>
                    <p className="card-text titolo_big3" dangerouslySetInnerHTML={{ __html: articolo[2].title.rendered }}></p>
                  </Link>
                  <p style={{ fontSize: '12px' }} className='text-secondary text-start-md-text-center mt-2'> di {articolo[0]._embedded['author']['0'].name}</p>
                </div>
              </div>
              <div className="card custom_card">
                <div className="card-body bordo-top  p-3 p-md-1 py-md-2">
                  <h6 className="card-subtitle mb-2 blu text-start-md-text-center mt-1 ">{articolo[5]._embedded['wp:term']['0'][0].name}</h6>
                  <Link to={`/mediadiario/articolo/${articolo[5].slug}`}>
                    <p className="card-text titolo_news" dangerouslySetInnerHTML={{ __html: articolo[5].title.rendered }}></p>
                  </Link>
                  <p style={{ fontSize: '12px' }} className='text-secondary text-start-md-text-center '> di {articolo[5]._embedded['author']['0'].name}</p>
                </div>
              </div>
            </div>

            <div className='col-12 col-md-6 order-3 col-lg-3 order-lg-3' >
              <div style={{ flexDirection: 'column' }} className='row'>
                <div className='col-12'>
                  <div className="card custom_card">
                    <Link to={`/mediadiario/articolo/${articolo[3].slug}`}>
                      <img src={articolo[3]._embedded['wp:featuredmedia']['0'].source_url} width={200} height={200} className="card-img-top custom_card_img3 " alt="..." />
                    </Link>
                    <div className="card-body p-3 p-md-1 py-md-2">
                      <h6 className="card-subtitle mb-2 blu">{articolo[3]._embedded['wp:term']['0'][0].name}</h6>
                      <Link to={`/mediadiario/articolo/${articolo[3].slug}`}>
                        <p className="card-text titolo_news" dangerouslySetInnerHTML={{ __html: articolo[3].title.rendered }}></p>
                      </Link>
                      <p style={{ fontSize: '12px' }} className='text-secondary d-flex align-self-start mt-2'> di {articolo[3]._embedded['author']['0'].name}</p>
                    </div>
                  </div>
                </div>
                <div className='col-12 mt-2'>
                  <div className="card custom_card">
                    <div className="card-body p-3 p-md-1 py-md-2">
                      <h6 className="card-subtitle mb-2 blu">{articolo[4]._embedded['wp:term']['0'][0].name}</h6>
                      <Link to={`/mediadiario/articolo/${articolo[4].slug}`}>
                        <p className="card-text titolo_news" dangerouslySetInnerHTML={{ __html: articolo[4].title.rendered }}></p>
                      </Link>
                      <p style={{ fontSize: '12px' }} className='text-secondary d-flex align-self-start mt-2'> di {articolo[0]._embedded['author']['0'].name}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
export default NewsContainer;
