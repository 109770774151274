import React from 'react';
import Ultimenews from '../../components/ultimenews/ultimenews';
import './articledetails.css';
const Skeletor = () => {
    return (
        <div className='p-4 p-md-5'>
            <div style={{ maxWidth: '1020px', margin: '0 auto' }} className='container'>
                <div className='row'>
                    <div className='col-12 col-md-8 pe-3 border-right'>
                        <div className='div_category order-2 order-lg-1  mb-3'>
                            <p style={{ height: '2rem', width: '30%', borderRadius: '5px' }} className=' bg-loading blu d-flex align-self-start mb-3 '></p>
                            <h3 style={{ height: '5rem', width: '90%', borderRadius: '5px' }} className='bg-loading mb-4'></h3>
                            <p style={{ fontSize: '14px',height: '1rem', width: '30%', borderRadius: '5px' }} className=' bg-loading text-secondary d-flex align-self-start  mt-2'> </p>
                        </div>
                            <div style={{ minHeight: '300px' }}className=" bg-loading card-img-top rounded-2  object-fit-cover order-1 order-lg-2 mb-4"/>
                        <div  />
                    </div>
                    <div className='col-12 col-md-4 ps-4'>
                        <h5 className='px-2 pt-5 pt-md-0' style={{ fontSize: '14px', fontWeight: 'bold' }}>Le ultime news</h5>
                        <div className='border-top-article pb-3 pb-md-0'></div>
                        <Ultimenews />
                    </div>
                </div>
            </div>
        </div>
    );
}
export default Skeletor;  
