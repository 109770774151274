import React from 'react';

const Skeletor = () => {
    return (
        <div className='p-4 p-md-5'>
            <div style={{ maxWidth: '1020px', margin: '0 auto' }} className='container'>
                <div className='row'>
                    <div className='col-12 col-md-8 pe-3 border-right'>
                        <div>
                            <div className='div_category order-2 order-lg-1'>
                                <div className='rounded ' style={{ width: '30%', height: '16px', backgroundColor: 'rgba(187, 187, 187, 0.3)' }}></div>
                                <div className='rounded my-2' style={{ width: '80%', height: '80px', backgroundColor: 'rgba(187, 187, 187, 0.3)' }}></div>
                                <div className='rounded' style={{ width: '50%', height: '16px', backgroundColor: 'rgba(187, 187, 187, 0.3)' }}></div>
                            </div>
                            <div className='  bg-loading card-img-top  object-fit-cover border-radius rounded category_img order-1 order-lg-2 my-4 ' style={{ width: '100%', height: '200px' }}></div>
                            <div className='row '>
                                <div className=' d-flex flex-column  '>
                                    <div className='row py-4 border_top'>
                                        {[...Array(4).keys()].map((index) => (
                                            <div key={index} className='col-12 col-md-6 mt-5'>
                                                <div className='rounded my-2' style={{ width: '50%', height: '16px',backgroundColor: 'rgba(187, 187, 187, 0.3)' }}></div>
                                                <div className='rounded ' style={{ width: '100%', height: '150px',backgroundColor: 'rgba(187, 187, 187, 0.3)' }}></div>

                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-12 col-md-4'>
                        {/* Qui va il componente skeletor per Ultimenews */}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Skeletor;
