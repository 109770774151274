import React from 'react';

const Skeletor = () => {
    return (
        <div className='container p-5 news_sub_container'>
            <div className="row">
                {[...Array(3).keys()].map((index) => (
                    <div key={index} className="col-12 col-md-6 order-2 col-lg-4 order-lg-1">
                        <div className="card custom_card p-md-3">
                        <div className='rounded my-2 div_sub' style={{ width: '100%', backgroundColor: 'rgba(187, 187, 187, 0.3)' }}></div>
                            <div className="card-body">
                                <h6 className="card-subtitle mb-2 blu"></h6>
                                <p className="card-text"></p>
                                <p  className='text-secondary d-flex align-self-start mt-2'> </p>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}
export default Skeletor;